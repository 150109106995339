import React from 'react';
import { ReactComponent as HypercubeLogo } from '../assets/logo/hypercube-capital-logo.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';

function Home() {
  return (
    <div className="h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center">
      <div className="max-w-xs lg:max-w-lg mx-auto flex flex-col items-center">
        <h1 className="tracking-hypercube text-2xl lg:text-4xl font-bold pl-4">HYPERCUBE</h1>
        <HypercubeLogo className="w-8 h-8 lg:w-12 lg:h-12 m-4 lg:m-4" />
        <h1 className="tracking-hypercube text-2xl lg:text-4xl font-bold pl-4">CAPITAL</h1>
        <p className="mt-6 lg:mt-8 lg:text-base text-center">
          Exploring the frontiers of permissionless finance.
        </p>
        <a href="https://twitter.com/hypercubecap">
          <Twitter className="h-6 w-6 lg:h-8 lg:w-8 mt-12 lg:mt-16 text-red-400 fill-current transition duration-300 hover:text-yellow-400" />
        </a>
      </div>
    </div>
  );
}

export default Home;
